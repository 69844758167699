import { lazy } from "react";

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/auth/auth1/login")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/auth1/register")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/auth1/forgot-password"))
);
const AuthCheckMail = Loadable(
  lazy(() => import("pages/auth/auth1/check-mail"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/auth/auth1/reset-password"))
);
const AuthCodeVerification = Loadable(
  lazy(() => import("pages/auth/auth1/code-verification"))
);
// render - sample page
const SamplePage = Loadable(
  lazy(() => import("pages/extra-pages/sample-page"))
);

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        // {
        //   path: '/',
        //   element: <AuthLogin />
        // },
        {
          path: "/",
          element: <AuthForgotPassword />,
        },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
        {
          path: "login",
          element: <AuthLogin />,
        },
        // {
        //   path: "sample-page",
        //   element: <SamplePage />,
        // },
        // {
        //   path: "register",
        //   element: <AuthRegister />,
        // },
        // {
        //   path: 'forgot-password',
        //   element: <AuthForgotPassword />
        // },
        // {
        //   path: 'check-mail',
        //   element: <AuthCheckMail />
        // },
        // {
        //   path: 'reset-password',
        //   element: <AuthResetPassword />
        // },
        // {
        //   path: 'code-verification',
        //   element: <AuthCodeVerification />
        // }
      ],
    },
  ],
};

export default LoginRoutes;

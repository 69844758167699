// project-imports
import contents from './contents';
import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [support, contents]
};

export default menuItems;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  DocumentCode2,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
} from "iconsax-react";

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: "other",
  title: <FormattedMessage id="others" />,
  type: "group",
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.samplePage
    },
  ]
};

export default support;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  Home3,
  Profile2User,
  Box1,
  DocumentForward,
  Category2,
  MessageQuestion,
  Award,
  DocumentCode
} from "iconsax-react";

// icons
const icons = {
  dashboard: Home3,
  client: Profile2User,
  standard: Award,
};

const contents = {
  id: "contents",
  title: <FormattedMessage id="contents" />,
  type: "group",
  children: [
    {
      id: "Users",
      title: <FormattedMessage id="Users" />,
      type: "item",
      // url: "/client/client-list",
      icon: icons.client,
    },
    {
      id: "Standards",
      title: <FormattedMessage id="Standards" />,
      type: "item",
      url: "/standards/standard-list",
      icon: icons.standard,
    },
  ],
};

export default contents;

import { isEmpty } from "lodash";
import { ApiServices } from "./apiServices";
import { Constants } from "./keyConstants";
import * as url from "./url_helper";
import { Box } from "@mui/material";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";

export const isUserLoggedIn = () => {
  let token = getSecureToken(Constants.KEY_USER_TOKEN);
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  if (isEmpty(token) && isEmpty(userData)) {
    return false;
  } else {
    return true;
  }
};

export const setSecureToken = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getSecureToken = (key) => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  } else {
    return false;
  }
};
export const extractContentFromHTML = (html) => {
  var span = document.createElement("span");
  span.innerHTML = html;
  return span.textContent || span.innerText;
};
export const logout = () => {
  localStorage.clear();
  window.location.replace("/#/login");
};

export const verifyDomain = () => {
  return new Promise(async (resolve, reject) => {
    let validDomain;
    const { hostname } = window.location;
    let subDomain = hostname.split(".")[0];
    if (subDomain === "client") return resolve({ validDomain: true });
    let getKey = JSON.parse(sessionStorage.getItem(`${subDomain}`));
    if (getKey !== null) return resolve({ validDomain: getKey });
    // let validDomain = subDomain === "raj" //change domain for static verification
    //make api call here to check the domain and store it in session storage
    let reqObj = {
      domainLink: hostname,
    };
    let uri = url.CHECK_VERIFIED_DOMAIN;
    await ApiServices.callServicePostWithBodyData(uri, reqObj)
      .then((response) => {
        validDomain = response.result.status;
        sessionStorage.setItem(
          `${subDomain}`,
          JSON.stringify(response.result.status)
        );
      })
      .catch((error) => {
        console.error("CHECK_VERIFIED_DOMAIN error", error);
      });
    return resolve({ validDomain: validDomain });
  });
};

export const modifyQuestions = (questionsData, standardName) => {
  let data = {
    standard: standardName,
    sections: {},
  };

  questionsData.forEach((question) => {
    const {
      section,
      questionContent,
      questionConstant,
      choices,
      parentQuestion,
      parentChoice,
      sectionConstant,
    } = question.question;

    if (!data.sections[section]) {
      data.sections[section] = {
        constants: sectionConstant,
        docType: question.docType,
        questions: {},
      };
    }

    if (question.questionType === "mcq Question") {
      data.sections[section].questions[questionContent] = {
        constants: questionConstant,
        answer:
          question?.question?.answer === true
            ? true
            : question?.question?.answer === false
            ? false
            : null,
        type: "radio",
        parentQuestionId: question._id,
        options: choices,
        suggestion: question.question.suggestions ?? "",
      };
    } else if (question.questionType === "mcmc Question") {
      if (
        questionContent ===
        "Provide Non-applicability of the quality management system. (if any requirement in clause 6, 7, or 8 of ISO 13485:2016 is not applicable due to the activities undertaken by the organization or the nature of the medical device, no need to include such requirement in the quality management system. for any clause that is determined to be non-applicable, provide the justification. Identified non applicable clause 6.4.2, 7.5.2, 7.5.3, 7.5.4, 7.5.5, 7.5.6, 7.5.7, 7.5.10, 8.3.4)"
      ) {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : [],
          type: "checkbox",
          parentQuestionId: question._id,
          options: choices,
          suggestion: question.question.suggestions ?? "",
          tableData: question.tableData != null ? question.tableData : [],
        };
      } else {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : [],
          type: "checkbox",
          parentQuestionId: question._id,
          options: choices,
          suggestion: question.question.suggestions ?? "",
          tableData:
            question.tableData === null
              ? getData(question.question.choices, question.question.answer)
              : question.tableData,
        };
      }
    } else if (question.questionType === "short Question") {
      if (parentQuestion && parentChoice) {
        data.sections[section].questions[parentQuestion].subquestion = {};
        data.sections[section].questions[parentQuestion].subquestion[
          questionContent
        ] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "text",
          subQuestionId: question._id,
          suggestion: question.question.suggestions ?? "",
        };
      } else {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "text",
          parentQuestionId: question._id,
          suggestion: question.question.suggestions ?? "",
        };
      }
    }
  });

  return data;
};

const getData = (data, keys) => {
  const dataMap = data.reduce((map, item) => {
    map[item.choice] = item.defaultData;
    return map;
  }, {});

  // Extract the defaultData arrays using the keys array
  const arrays = keys.map((key) => dataMap[key] || []);

  // Create the output array by combining corresponding elements from the extracted arrays
  const result = arrays[0].map((_, index) => {
    const obj = {};
    keys.forEach((key, keyIndex) => {
      const labelKey = key.replace(/\s+/g, "").toLowerCase(); // Convert 'Title' to 'title', 'Prepared By' to 'preparedby'
      // Check if defaultData array exists before accessing its elements
      obj[labelKey] = arrays[keyIndex][index]
        ? arrays[keyIndex][index].label
        : null;
    });
    return obj;
  });

  return result;
};

export const generateTableHTML = (data) => {
  const keys = Object.keys(data[0]);
  const headers = keys.map((key) => key.charAt(0).toUpperCase() + key.slice(1));
  let html = `<table style="border-collapse: collapse; width: 100%">`;

  html += "<tbody>";
  html += "<tr>";
  headers.forEach((header) => {
    html += `<td ><strong>${header}</strong></td>`;
  });
  html += "</tr>";

  // Add rows
  data.forEach((row) => {
    html += "<tr>";
    headers.forEach((header) => {
      const key = header.toLowerCase().replace(/ /g, "");
      html += `<td>${row[key]}</td>`;
    });
    html += "</tr>";
  });

  html += `</tbody></table>`;
  return html;
};
export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const dispatchFunction = (response) => {
  dispatch(
    openSnackbar({
      open: true,
      message: response.result.message,
      variant: "alert",
      alert: {
        color: response.result.type == "success" ? "success" : "error",
      },
      close: false,
    })
  );
};

export function formatKeyToCameCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

export function formatKey(key) {
  return (
    key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .charAt(0)
      .toUpperCase() + key.slice(1).replace(/[A-Z]/g, " $&")
  );
}

import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Box,
  ClickAwayListener,
  Link,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import { ThemeMode } from "config";
import { Danger, Notification, TickSquare } from "iconsax-react";
import socket from "services/socket";
import NotificationStyle from "components/NotificationStyle";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { format } from "date-fns";

const NotificationPage = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tempNotification, setTempNotification] = useState(null);

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user_data"))
  );

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const time = format(date, "hh:mm a");
    const formattedDate = format(date, "do MMMM yyyy");
    return { time, formattedDate };
  };

  const handleNotification = async () => {
    const reqObj = { clientUserId: user._id };
    let uri = url.GET_NOTIFICATIONS;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.success) {
      setNotifications(result?.notifications?.notifications.reverse());
    }
  };

  const countMarkedCompleteFalse = notifications.reduce((acc, obj) => {
    if (!obj.markedComplete) {
      acc++;
    }
    return acc;
  }, 0);

  useEffect(() => {
    handleNotification();
    const userId = user._id;
    socket.emit("join", { id: userId });

    socket.on("new_msg", (data) => {
      const newNotification = {
        title: data.title,
        message: data.message,
        timestamp: new Date(),
      };

      setTempNotification(newNotification);
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
    });

    return () => {
      socket.off("new_msg");
    };
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const actionSX = {
    mt: "6px",
    ml: 1,
    top: "auto",
    right: "auto",
    alignSelf: "flex-start",
    transform: "none",
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        size="large"
        sx={{
          color: "secondary.main",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          p: 1,
        }}
      >
        <Badge
          badgeContent={countMarkedCompleteFalse ?? 0}
          color="success"
          sx={{ "& .MuiBadge-badge": { top: 2, right: 4 } }}
        >
          <Notification variant="Bold" />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top" : "top-right"}
            sx={{ overflow: "hidden" }}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5">Notifications</Typography>
                  </Stack>
                  <Box
                    sx={{
                      maxHeight: 300,
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: 6,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                  >
                    {notifications.length === 0 ? (
                      <Typography
                        variant="body2"
                        sx={{ textAlign: "center", mt: 2, padding: 5 }}
                      >
                        Oops! Looks like there are no notifications here.
                      </Typography>
                    ) : (
                      <List
                        component="nav"
                        sx={{
                          "& .MuiListItemButton-root": {
                            p: 1.5,
                            my: 1.5,
                            border: `1px solid ${theme.palette.divider}`,
                            "&:hover": {
                              bgcolor: "primary.lighter",
                              borderColor: theme.palette.primary.light,
                            },
                            "& .MuiListItemSecondaryAction-root": {
                              ...actionSX,
                              position: "relative",
                            },
                          },
                        }}
                      >
                        {notifications.map((notification, index) => {
                          const { time, formattedDate } = formatDate(
                            notification.timestamp
                          );
                          return (
                            <ListItemButton
                              key={index}
                              onClick={() =>
                                console.log(
                                  `Notification ID: ${notification._id}`
                                )
                              }
                              sx={{
                                bgcolor: "background.paper",
                                "&:hover": {
                                  bgcolor: "primary.light",
                                  ".MuiTypography-root": {
                                    color: "primary.main",
                                  },
                                },
                              }}
                            >
                              <ListItemAvatar>
                                {notification.markedComplete ? (
                                  <TickSquare
                                    size="32"
                                    color="#37D67A"
                                    variant="Bulk"
                                  />
                                ) : (
                                  <Danger
                                    size="32"
                                    color="#F47373"
                                    variant="Bulk"
                                  />
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "medium",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {notification.title}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "text.secondary",
                                      marginRight: "8px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {notification.message}
                                  </Typography>
                                }
                              />
                              <ListItemSecondaryAction>
                                <Typography
                                  variant="caption"
                                  noWrap
                                  sx={{ fontStyle: "italic", display: "block" }}
                                >
                                  {time}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  noWrap
                                  sx={{ fontStyle: "italic", display: "block" }}
                                >
                                  {formattedDate}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItemButton>
                          );
                        })}
                      </List>
                    )}
                  </Box>
                  {/* <Stack direction="row" justifyContent="center">
                    <Link href="#" variant="h6" color="primary">
                      View all
                    </Link>
                  </Stack> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{
            width: "100%",
            bgcolor: "#8B0000", // Dark red color
            color: "#FFFFFF", // White font color
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {tempNotification && (
        <NotificationStyle notification={tempNotification} />
      )}
    </Box>
  );
};

export default NotificationPage;

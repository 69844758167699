//auth
export const LOGIN = "/auth/login";
export const CLIENTLOGIN = "/client/auth/login";

//Category
export const GET_ALL_CATEGORIES = "/category";
export const CREATE_CATEGORY = "/category";
export const UPDATE_CATEGORY = "/category/";

//questions
export const CREATE_QUESTION = "/question";
export const UPDATE_QUESTION = "/question/";
export const CREATE_QUESTION_MCMC = "/question/mcmc";
export const GET_ALL_QUESTION = "/question";
export const GET_QUESTIONS_BY_CATEGORYID = "/question/parent";
export const GET_PARENT_QUESTION = "/question/list"
export const GET_SUB_QUESTION = "/question/sub";
export const GET_QUESTION_LIST = "/question/get-list";
export const GET_QUESTION_BY_ID = "/question/";

//choices
export const DELETE_CHOICE = "/choice/";
export const GET_CHOICES = "/choice/question";
export const GET_CHOICES_BY_QUESTION_ID = "/choice/question";

//product
export const GET_ALL_PRODUCTS = "/product";
export const CREATE_PRODUCT = "/product";
export const GET_CLIENT_PRODUCT_BY_ID = "/client/product/";
export const UPDATE_PRODUCT = "/product/";

//productCategory
export const GET_ALL_CATEGORIES_BY_PRODUCT_ID = "/product-category/list";

//productCategory

//template
export const CREATE_TEMPLATE = "/template";
export const GET_ALL_TEMPLATE = "/template";
export const GET_TEMPLATE_BY_ID = "/template/";
export const UPDATE_TEMPLATE = "/template/";
export const SUBMIT_ANSWER = "/client/document/generate";

//client document
export const GET_OUTPUT = "/client/document/view";

//constants
export const CONSTANT_CHECK  = "/constant/check";
export const GET_CONSTANTS_BY_CATEGORY = "/constant/list"; 

// verified-domain
export const CHECK_VERIFIED_DOMAIN = "/client/organization/verify-domain";

// verify Client
export const VERIFY_EMAIL = "/client/auth/verify";

// Save password
export const SAVE_PASSWORD = "/client/auth/update-password";

//Client login
export const SIGN_IN = "/client/auth/login";

//Get client products
export const GET_CLIENTS_PRODUCTS_BY_ORGANIZATION_ID = "/client/organization/";

//Update Client
export const UPDATE_USERS = "/client/users/";
export const GET_USERS = "/client/users/";

//Product All Document By Org and ProdcutId
export const GET_DOCUMENT_BY_PRODUCT_AND_ORG_ID = "/client/document/view-all";
export const GET_DOCUMENT_BY_PRODUCT_ID = "/client/document/";
export const UPDATE_DOCUMENT = "/client/document/";


//STANDARDS
export const LIST_OF_ASSIGN_STANDARD = "/client/users/get-standards";
export const GET_QUESTION_BY_STANDARD = "/client/qa/update";

//CLIENT QUESTIONS
export const CREATE_CLIENT_QUESTION  = "/client/qa";
export const GET_CLIENT_QUESTION  = "/client/qa/by-client";
export const UPDATE_ANSWER = "/client/qa/update";


// Quality Manual
export const ADD_MANUAL = "/qmsSet/qualityManual";
export const UPDATE_MANUAL = "/qmsSet/qualityManual/update";
export const GET_ALL_MANUAL = "/qmsSet/qualityManual/get";
export const GET_MANUAL_BY_ID = "/qmsSet/qualityManual/get-manual";
export const MANUAL_LIST = "/qmsSet/qualityManual";
export const ADD_STANDARD = "/standard/";
export const UPDATE_QM = "/qmsSet/qualityManual/"
export const DOWNLOAD_MANUAL = "/qmsSet/qualityManual/download";

//Notifications
export const GET_NOTIFICATIONS = "/notifications/get";


//Headers
export const CREATE_HEADER = "/header/get";
export const SAVE_HEADER = "/header/update";
export const GET_HEADER = "/header/";


import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";


const MaintenanceError = Loadable(
  lazy(() => import("pages/maintenance/error/404"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/error/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon/coming-soon"))
);

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("pages/extra-pages/sample-page"))
);

// render - product page of clinet
const DashboardPage = Loadable(lazy(() => import("pages/Product/dashboard")));
const AssignedStandardList = Loadable(
  lazy(() => import("pages/standards/AssignedStandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/standards/StandardDetails"))
);
const Question = Loadable(
  lazy(() => import("pages/StandardQuestion/Question"))
);

const QualityManualList = Loadable(
  lazy(() =>
    import("pages/standards/QualityManual/QualityManualList")
  )
);

const AddEditManual = Loadable(
  lazy(() => import("pages/standards/QualityManual/AddEditManual"))
);

const HeaderField = Loadable(
  lazy(() => import("pages/standards/QualityManual/HeaderField"))
);

const ChartTable = Loadable(
  lazy(() => import("pages/standards/QualityManual/ChartTable"))
);

const Theme = Loadable(
  lazy(() => import("pages/standards/QualityManual/Theme"))
);

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "sample-page",
          element: <SamplePage />,
        },
        {
          path: "dashboard",
          element: <DashboardPage />,
        },
        {
          path: "standards",
          children: [
            {
              path: "standard-list",
              element: <AssignedStandardList />,
            },
            {
              path: "standard-details",
              element: <StandardDetails />,
            },
            {
              path: "quality-manual",
              element: <QualityManualList />,
            },
            {
              path: "addEdit-manual",
              element: <AddEditManual />,
            },
            { path: "header-field", element: <HeaderField /> },
            { path: "themes", element: <Theme /> },
            { path: "organogram", element: <ChartTable /> },
          ],
        },
        {
          path: "standardsQuestion",
          children: [
            {
              path: "question",
              element: <Question />,
            },
          ],
        },
      ],
    },
    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;

import React, { useState, useEffect } from "react";
import { Snackbar, Alert, IconButton, Typography, Box } from "@mui/material";
import { CloseCircle } from "iconsax-react";

const NotificationStyle = ({ notification }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={{
        "& .MuiSnackbarContent-root": {
          width: "400px", // Adjust this value as needed
        },
      }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "8px",
          boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
            sx={{ color: "#fff" }}
          >
            <CloseCircle fontSize="inherit" />
          </IconButton>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", flexGrow: 1, marginRight: "8px" }}
            >
              {notification.title}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: "#bbb", marginLeft: "auto" }}
            >
              now
            </Typography>
          </Box>
          <Typography variant="body2">{notification.message}</Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default NotificationStyle;

// import { useRoutes } from "react-router-dom";

// // project-imports
// import LoginRoutes from "./LoginRoutes";
// import MainRoutes from "./MainRoutes";
// import { lazy, useEffect, useState } from "react";
// import Loadable from "components/Loadable";
// import { verifyDomain } from "../services/utils";
// const MaintenanceError = Loadable(
//   lazy(() => import("pages/maintenance/error/404"))
// );

// // ==============================|| ROUTES RENDER ||============================== //

// export default function ThemeRoutes() {
//   const [isVerifiedDomain, setVerifiedDomain] = useState(false);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     verifyDomain()
//       .then(async (data) => {
//         setLoading(false);

//         if (data.validDomain == "true" || data.validDomain == true)
//           return setVerifiedDomain(true);
//         setVerifiedDomain(false);
//       })
//       .catch((error) => {
//         console.log("verifyDomain Error=>", error);
//         setLoading(false);
//       });
//   }, []);

//   if (isVerifiedDomain) {
//     return useRoutes([LoginRoutes, MainRoutes]);
//   } else {
//     return <MaintenanceError />;
//   }
// }

import { useRoutes } from "react-router-dom";
import { useEffect, useState, lazy } from "react";
import { verifyDomain } from "../services/utils";
import Loadable from "components/Loadable";
const MaintenanceError = Loadable(
  lazy(() => import("pages/maintenance/error/404"))
);
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  const [isVerifiedDomain, setVerifiedDomain] = useState(false);
  const [loading, setLoading] = useState(true); // Set loading state to true initially

  useEffect(() => {
    verifyDomain()
      .then(async (data) => {
        setLoading(false);
        if (data.validDomain) {
          setVerifiedDomain(true);
        } else {
          setVerifiedDomain(false);
        }
      })
      .catch((error) => {
        console.log("verifyDomain Error=>", error);
        setLoading(false);
      });
  }, []);

  // Always call useRoutes, but conditionally render the result based on isVerifiedDomain
  const routes = useRoutes([LoginRoutes, MainRoutes]);

  // Render the appropriate content based on isVerifiedDomain
  if (loading) {
    // Optionally, you can render a loading indicator while the domain verification is in progress
    return <div>Loading...</div>;
  } else if (isVerifiedDomain) {
    return routes;
  } else {
    return <MaintenanceError />;
  }
}

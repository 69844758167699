var service = {};
// Local
if (
  window.location.href.startsWith("http://localhost") ||
  window.location.hostname.endsWith(".me.mavenregulatory.com")
) {
  service.API_URL = "http://localhost:8001/api";
  service.IMG_URL = "http://localhost:8001/";
  service.PROXY_URL = "http://localhost:8002/";
} else if (window.location.hostname.endsWith(".mavenprofserv.us")) {
  service.API_URL = "https://api.mavenprofserv.us/api";
  service.IMG_URL = "http://api.mavenprofserv.us/";
  service.PROXY_URL = "https://proxy.mavenprofserv.com/api2/proxy/";
} else {
  service.API_URL = "https://api.mavenregtech.com/api";
  service.IMG_URL = "http://api.mavenregtech.com/";
  service.PROXY_URL = "https://proxy-live.mavenprofserv.com/api2/proxy/";
}

export default service;
